import { CSSProperties } from 'react';

type StatsEditIconProps = {
  className?: string;
  fill?: string;
  style?: CSSProperties;
};

export default function StatsEditIcon({
  className,
  fill = 'currentColor',
  style,
}: StatsEditIconProps) {
  return (
    <svg
      className={className}
      style={{
        ...style,
        height: '1em',
      }}
      height="15"
      width="15"
      viewBox="0 0 15 15"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.722656 1.75001C0.722656 0.954075 1.36789 0.308838 2.16383 0.308838H13.2815C14.0774 0.308838 14.7227 0.954075 14.7227 1.75001V9.26472C14.7227 10.0607 14.0774 10.7059 13.2815 10.7059H11.2741V10.2941H13.2815C13.85 10.2941 14.3109 9.83325 14.3109 9.26472V1.75001C14.3109 1.18149 13.85 0.720603 13.2815 0.720603H2.16383C1.5953 0.720603 1.13442 1.18149 1.13442 1.75001V9.26472C1.13442 9.83325 1.5953 10.2941 2.16383 10.2941H3.39913V10.7059H2.16383C1.36789 10.7059 0.722656 10.0607 0.722656 9.26472V1.75001Z"
        fill="currentColor"
      />
      <path
        d="M3.39913 7.7206C3.39913 7.6069 3.4913 7.51472 3.60501 7.51472H11.8403C11.954 7.51472 12.0462 7.6069 12.0462 7.7206C12.0462 7.83431 11.954 7.92649 11.8403 7.92649H3.60501C3.4913 7.92649 3.39913 7.83431 3.39913 7.7206Z"
        fill="currentColor"
      />
      <path
        d="M10.5849 1.44218C10.6641 1.4344 10.7406 1.47293 10.7816 1.54115L12.0168 3.59997C12.0753 3.69747 12.0437 3.82394 11.9462 3.88244C11.8487 3.94094 11.7223 3.90932 11.6638 3.81182L10.652 2.12555L9.35761 5.73134C9.32959 5.8094 9.25747 5.86302 9.17465 5.86738C9.09183 5.87173 9.01448 5.82597 8.97843 5.75129L7.75062 3.20796L6.47526 6.76075C6.448 6.83667 6.37892 6.88969 6.29853 6.89637C6.21814 6.90305 6.14126 6.86217 6.10185 6.79179L4.85437 4.56414L3.79062 6.78028C3.74141 6.88279 3.61843 6.926 3.51592 6.8768C3.41341 6.8276 3.3702 6.70461 3.4194 6.6021L4.6547 4.02857C4.68795 3.95929 4.75702 3.9143 4.83382 3.91188C4.91063 3.90946 4.98239 3.95002 5.01994 4.01707L6.24036 6.19639L7.52888 2.60692C7.5569 2.52887 7.62902 2.47524 7.71184 2.47089C7.79466 2.46653 7.87201 2.51229 7.90806 2.58698L9.13587 5.1303L10.4112 1.57751C10.4381 1.50264 10.5057 1.44995 10.5849 1.44218Z"
        fill="currentColor"
      />
      <path
        d="M4.22266 10.0883C3.99524 10.0883 3.81089 10.2726 3.81089 10.5C3.81089 10.7274 3.99524 10.9118 4.22266 10.9118C4.45007 10.9118 4.63442 10.7274 4.63442 10.5C4.63442 10.2726 4.45007 10.0883 4.22266 10.0883ZM3.39913 10.5C3.39913 10.0452 3.76783 9.67649 4.22266 9.67649C4.67748 9.67649 5.04619 10.0452 5.04619 10.5C5.04619 10.9548 4.67748 11.3235 4.22266 11.3235C3.76783 11.3235 3.39913 10.9548 3.39913 10.5Z"
        fill="currentColor"
      />
      <path
        d="M4.22266 9.98531C4.10895 9.98531 4.01677 9.89313 4.01677 9.77943V8.75001C4.01677 8.63631 4.10895 8.54413 4.22266 8.54413C4.33636 8.54413 4.42854 8.63631 4.42854 8.75001V9.77943C4.42854 9.89313 4.33636 9.98531 4.22266 9.98531Z"
        fill="currentColor"
      />
      <path
        d="M4.22266 13.6912C4.10895 13.6912 4.01677 13.599 4.01677 13.4853V11.3235C4.01677 11.2098 4.10895 11.1177 4.22266 11.1177C4.33636 11.1177 4.42854 11.2098 4.42854 11.3235V13.4853C4.42854 13.599 4.33636 13.6912 4.22266 13.6912Z"
        fill="currentColor"
      />
      <path
        d="M6.69324 10.9118C6.46583 10.9118 6.28148 11.0961 6.28148 11.3235C6.28148 11.551 6.46583 11.7353 6.69324 11.7353C6.92066 11.7353 7.10501 11.551 7.10501 11.3235C7.10501 11.0961 6.92066 10.9118 6.69324 10.9118ZM5.86972 11.3235C5.86972 10.8687 6.23842 10.5 6.69324 10.5C7.14807 10.5 7.51677 10.8687 7.51677 11.3235C7.51677 11.7784 7.14807 12.1471 6.69324 12.1471C6.23842 12.1471 5.86972 11.7784 5.86972 11.3235Z"
        fill="currentColor"
      />
      <path
        d="M6.69324 10.7059C6.57954 10.7059 6.48736 10.6137 6.48736 10.5V8.75001C6.48736 8.63631 6.57954 8.54413 6.69324 8.54413C6.80695 8.54413 6.89913 8.63631 6.89913 8.75001V10.5C6.89913 10.6137 6.80695 10.7059 6.69324 10.7059Z"
        fill="currentColor"
      />
      <path
        d="M6.69324 13.6912C6.57954 13.6912 6.48736 13.599 6.48736 13.4853V11.9412C6.48736 11.8275 6.57954 11.7353 6.69324 11.7353C6.80695 11.7353 6.89913 11.8275 6.89913 11.9412V13.4853C6.89913 13.599 6.80695 13.6912 6.69324 13.6912Z"
        fill="currentColor"
      />
      <path
        d="M9.16383 9.67649C8.93642 9.67649 8.75207 9.86084 8.75207 10.0883C8.75207 10.3157 8.93642 10.5 9.16383 10.5C9.39124 10.5 9.5756 10.3157 9.5756 10.0883C9.5756 9.86084 9.39124 9.67649 9.16383 9.67649ZM8.3403 10.0883C8.3403 9.63343 8.70901 9.26472 9.16383 9.26472C9.61866 9.26472 9.98736 9.63343 9.98736 10.0883C9.98736 10.5431 9.61866 10.9118 9.16383 10.9118C8.70901 10.9118 8.3403 10.5431 8.3403 10.0883Z"
        fill="currentColor"
      />
      <path
        d="M9.16383 9.4706C9.05013 9.4706 8.95795 9.37843 8.95795 9.26472V8.75001C8.95795 8.63631 9.05013 8.54413 9.16383 8.54413C9.27754 8.54413 9.36971 8.63631 9.36971 8.75001V9.26472C9.36971 9.37843 9.27754 9.4706 9.16383 9.4706Z"
        fill="currentColor"
      />
      <path
        d="M9.16383 13.6912C9.05013 13.6912 8.95795 13.599 8.95795 13.4853V10.9118C8.95795 10.7981 9.05013 10.7059 9.16383 10.7059C9.27754 10.7059 9.36971 10.7981 9.36971 10.9118V13.4853C9.36971 13.599 9.27754 13.6912 9.16383 13.6912Z"
        fill="currentColor"
      />
      <path
        d="M11.2227 11.7353C10.9952 11.7353 10.8109 11.9197 10.8109 12.1471C10.8109 12.3745 10.9952 12.5588 11.2227 12.5588C11.4501 12.5588 11.6344 12.3745 11.6344 12.1471C11.6344 11.9197 11.4501 11.7353 11.2227 11.7353ZM10.3991 12.1471C10.3991 11.6923 10.7678 11.3235 11.2227 11.3235C11.6775 11.3235 12.0462 11.6923 12.0462 12.1471C12.0462 12.6019 11.6775 12.9706 11.2227 12.9706C10.7678 12.9706 10.3991 12.6019 10.3991 12.1471Z"
        fill="currentColor"
      />
      <path
        d="M11.2227 11.6324C11.109 11.6324 11.0168 11.5402 11.0168 11.4265V8.75001C11.0168 8.63631 11.109 8.54413 11.2227 8.54413C11.3364 8.54413 11.4285 8.63631 11.4285 8.75001V11.4265C11.4285 11.5402 11.3364 11.6324 11.2227 11.6324Z"
        fill="currentColor"
      />
      <path
        d="M11.2227 13.6912C11.109 13.6912 11.0168 13.599 11.0168 13.4853V12.8677C11.0168 12.754 11.109 12.6618 11.2227 12.6618C11.3364 12.6618 11.4285 12.754 11.4285 12.8677V13.4853C11.4285 13.599 11.3364 13.6912 11.2227 13.6912Z"
        fill="currentColor"
      />
      <path
        d="M2.47266 1.90443C2.47266 2.04656 2.35744 2.16178 2.2153 2.16178C2.07317 2.16178 1.95795 2.04656 1.95795 1.90443C1.95795 1.76229 2.07317 1.64707 2.2153 1.64707C2.35744 1.64707 2.47266 1.76229 2.47266 1.90443Z"
        fill="currentColor"
      />
      <path
        d="M13.4874 1.90443C13.4874 2.04656 13.3721 2.16178 13.23 2.16178C13.0879 2.16178 12.9727 2.04656 12.9727 1.90443C12.9727 1.76229 13.0879 1.64707 13.23 1.64707C13.3721 1.64707 13.4874 1.76229 13.4874 1.90443Z"
        fill="currentColor"
      />
      <path
        d="M2.47266 9.11031C2.47266 9.25244 2.35744 9.36766 2.2153 9.36766C2.07317 9.36766 1.95795 9.25244 1.95795 9.11031C1.95795 8.96818 2.07317 8.85296 2.2153 8.85296C2.35744 8.85296 2.47266 8.96818 2.47266 9.11031Z"
        fill="currentColor"
      />
      <path
        d="M13.4874 9.11031C13.4874 9.25244 13.3721 9.36766 13.23 9.36766C13.0879 9.36766 12.9727 9.25244 12.9727 9.11031C12.9727 8.96818 13.0879 8.85296 13.23 8.85296C13.3721 8.85296 13.4874 8.96818 13.4874 9.11031Z"
        fill="currentColor"
      />
    </svg>
  );
}
