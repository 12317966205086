import { CSSProperties } from 'react';

type EyeTargetIconProps = {
  className?: string;
  fill?: string;
  style?: CSSProperties;
};

export default function EyeTargetIcon({
  className,
  fill = 'currentColor',
  style,
}: EyeTargetIconProps) {
  return (
    <svg
      className={className}
      style={{
        ...style,
        height: '1em',
      }}
      height="15"
      width="15"
      viewBox="0 0 15 15"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.06464 0.425856C0.711849 0.425856 0.425856 0.711849 0.425856 1.06464V3.24715C0.425856 3.36475 0.330525 3.46008 0.212928 3.46008C0.095331 3.46008 0 3.36475 0 3.24715V1.06464C0 0.476655 0.476655 0 1.06464 0H3.24715C3.36475 0 3.46008 0.095331 3.46008 0.212928C3.46008 0.330525 3.36475 0.425856 3.24715 0.425856H1.06464Z"
        fill="currentColor"
      />
      <path
        d="M1.06464 13.5741C0.711849 13.5741 0.425856 13.2882 0.425856 12.9354V10.7529C0.425856 10.6353 0.330525 10.5399 0.212928 10.5399C0.095331 10.5399 0 10.6353 0 10.7529V12.9354C0 13.5233 0.476655 14 1.06464 14H3.24715C3.36475 14 3.46008 13.9047 3.46008 13.7871C3.46008 13.6695 3.36475 13.5741 3.24715 13.5741H1.06464Z"
        fill="currentColor"
      />
      <path
        d="M11.6578 0.425856C12.0106 0.425856 12.2966 0.711849 12.2966 1.06464V3.24715C12.2966 3.36475 12.3919 3.46008 12.5095 3.46008C12.6271 3.46008 12.7224 3.36475 12.7224 3.24715V1.06464C12.7224 0.476655 12.2458 0 11.6578 0H9.47529C9.35769 0 9.26236 0.095331 9.26236 0.212928C9.26236 0.330525 9.35769 0.425856 9.47529 0.425856H11.6578Z"
        fill="currentColor"
      />
      <path
        d="M11.6578 13.5741C12.0106 13.5741 12.2966 13.2882 12.2966 12.9354V10.7529C12.2966 10.6353 12.3919 10.5399 12.5095 10.5399C12.6271 10.5399 12.7224 10.6353 12.7224 10.7529V12.9354C12.7224 13.5233 12.2458 14 11.6578 14H9.47529C9.35769 14 9.26236 13.9047 9.26236 13.7871C9.26236 13.6695 9.35769 13.5741 9.47529 13.5741H11.6578Z"
        fill="currentColor"
      />
      <path
        d="M6.38783 8.67681C7.38741 8.67681 8.19772 7.86649 8.19772 6.86692C8.19772 5.86735 7.38741 5.05703 6.38783 5.05703C5.38826 5.05703 4.57795 5.86735 4.57795 6.86692C4.57795 7.86649 5.38826 8.67681 6.38783 8.67681ZM6.38783 9.10266C7.6226 9.10266 8.62358 8.10169 8.62358 6.86692C8.62358 5.63215 7.6226 4.63118 6.38783 4.63118C5.15307 4.63118 4.15209 5.63215 4.15209 6.86692C4.15209 8.10169 5.15307 9.10266 6.38783 9.10266Z"
        fill="currentColor"
      />
      <path
        d="M0.919661 6.94279C2.49009 8.70153 4.40971 10.1673 6.38783 10.1673C8.38171 10.1673 10.4089 8.88423 11.8692 6.94989C10.4091 5.10889 8.47778 3.77947 6.38783 3.77947C4.4036 3.77947 2.48169 5.1012 0.919661 6.94279ZM0.474684 6.81088C2.0861 4.86195 4.16324 3.35361 6.38783 3.35361C8.71362 3.35361 10.7957 4.86001 12.3055 6.81647L12.4032 6.9431L12.3088 7.07223C10.7944 9.14362 8.60851 10.5932 6.38783 10.5932C4.15836 10.5932 2.08026 8.91635 0.478576 7.08681L0.359239 6.9505L0.474684 6.81088Z"
        fill="currentColor"
      />
      <path
        d="M6.38783 2.5019C6.27024 2.5019 6.17491 2.40657 6.17491 2.28897V1.22433C6.17491 1.10674 6.27024 1.01141 6.38783 1.01141C6.50543 1.01141 6.60076 1.10674 6.60076 1.22433V2.28897C6.60076 2.40657 6.50543 2.5019 6.38783 2.5019Z"
        fill="currentColor"
      />
      <path
        d="M8.33271 2.71483C8.23087 2.65603 8.19597 2.52581 8.25477 2.42396L8.78709 1.50196C8.84589 1.40012 8.97611 1.36522 9.07796 1.42402C9.1798 1.48282 9.21469 1.61305 9.15589 1.71489L8.62357 2.63689C8.56478 2.73873 8.43455 2.77363 8.33271 2.71483Z"
        fill="currentColor"
      />
      <path
        d="M4.44296 2.71483C4.5448 2.65603 4.57969 2.52581 4.52089 2.42396L3.98857 1.50196C3.92978 1.40012 3.79955 1.36522 3.69771 1.42402C3.59587 1.48282 3.56098 1.61305 3.61977 1.71489L4.15209 2.63689C4.21089 2.73873 4.34112 2.77363 4.44296 2.71483Z"
        fill="currentColor"
      />
      <path
        d="M2.60691 3.63699C2.6943 3.5583 2.70135 3.42367 2.62267 3.33628L1.91028 2.5451C1.8316 2.45771 1.69696 2.45065 1.60957 2.52934C1.52218 2.60803 1.51512 2.74266 1.59381 2.83005L2.30619 3.62123C2.38488 3.70862 2.51951 3.71568 2.60691 3.63699Z"
        fill="currentColor"
      />
      <path
        d="M10.1688 3.63699C10.0814 3.5583 10.0743 3.42367 10.153 3.33628L10.8654 2.5451C10.9441 2.45771 11.0787 2.45065 11.1661 2.52934C11.2535 2.60803 11.2605 2.74266 11.1819 2.83005L10.4695 3.62123C10.3908 3.70862 10.2562 3.71568 10.1688 3.63699Z"
        fill="currentColor"
      />
      <path
        d="M2.29043 10.0248C2.20304 9.94614 2.06841 9.95319 1.98972 10.0406L1.27734 10.8318C1.19865 10.9192 1.20571 11.0538 1.2931 11.1325C1.38049 11.2112 1.51512 11.2041 1.59381 11.1167L2.30619 10.3255C2.38488 10.2381 2.37782 10.1035 2.29043 10.0248Z"
        fill="currentColor"
      />
      <path
        d="M10.4852 10.0248C10.5726 9.94614 10.7073 9.95319 10.7859 10.0406L11.4983 10.8318C11.577 10.9192 11.57 11.0538 11.4826 11.1325C11.3952 11.2112 11.2605 11.2041 11.1819 11.1167L10.4695 10.3255C10.3908 10.2381 10.3978 10.1035 10.4852 10.0248Z"
        fill="currentColor"
      />
      <path
        d="M8.33271 11.1255C8.23087 11.1843 8.19597 11.3145 8.25477 11.4163L8.78709 12.3383C8.84589 12.4402 8.97611 12.4751 9.07796 12.4163C9.1798 12.3575 9.21469 12.2273 9.15589 12.1254L8.62357 11.2034C8.56478 11.1016 8.43455 11.0667 8.33271 11.1255Z"
        fill="currentColor"
      />
      <path
        d="M4.44296 11.1255C4.5448 11.1843 4.57969 11.3145 4.52089 11.4163L3.98857 12.3383C3.92978 12.4402 3.79955 12.4751 3.69771 12.4163C3.59587 12.3575 3.56098 12.2273 3.61977 12.1254L4.15209 11.2034C4.21089 11.1016 4.34112 11.0667 4.44296 11.1255Z"
        fill="currentColor"
      />
      <path
        d="M6.38783 12.9354C6.27024 12.9354 6.17491 12.84 6.17491 12.7224V11.6578C6.17491 11.5402 6.27024 11.4449 6.38783 11.4449C6.50543 11.4449 6.60076 11.5402 6.60076 11.6578V12.7224C6.60076 12.84 6.50543 12.9354 6.38783 12.9354Z"
        fill="currentColor"
      />
    </svg>
  );
}
