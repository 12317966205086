import { CSSProperties } from 'react';

type GripDotsVerticalIconProps = {
  className?: string;
  fill?: string;
  style?: CSSProperties;
};

export default function GripDotsVerticalIcon({
  className,
  fill = '#8C8C8C',
  style,
}: GripDotsVerticalIconProps) {
  return (
    <svg
      className={className}
      style={style}
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.9375 7.5C1.45527 7.5 1.875 7.91973 1.875 8.4375C1.875 8.95527 1.45527 9.375 0.9375 9.375C0.419733 9.375 -1.83471e-08 8.95527 -4.09794e-08 8.4375C-6.36117e-08 7.91973 0.419733 7.5 0.9375 7.5Z" />
      <path d="M5.0625 7.5C5.58027 7.5 6 7.91973 6 8.4375C6 8.95527 5.58027 9.375 5.0625 9.375C4.54473 9.375 4.125 8.95527 4.125 8.4375C4.125 7.91973 4.54473 7.5 5.0625 7.5Z" />
      <path d="M5.0625 3.75C5.58027 3.75 6 4.16973 6 4.6875C6 5.20527 5.58027 5.625 5.0625 5.625C4.54473 5.625 4.125 5.20527 4.125 4.6875C4.125 4.16973 4.54473 3.75 5.0625 3.75Z" />
      <path d="M5.0625 -2.21289e-07C5.58027 -2.43921e-07 6 0.419733 6 0.9375C6 1.45527 5.58027 1.875 5.0625 1.875C4.54473 1.875 4.125 1.45527 4.125 0.9375C4.125 0.419733 4.54473 -1.98657e-07 5.0625 -2.21289e-07Z" />
      <path d="M0.9375 3.75C1.45527 3.75 1.875 4.16973 1.875 4.6875C1.875 5.20527 1.45527 5.625 0.9375 5.625C0.419733 5.625 -1.82265e-07 5.20527 -2.04897e-07 4.6875C-2.27529e-07 4.16973 0.419733 3.75 0.9375 3.75Z" />
      <path d="M0.9375 -4.09794e-08C1.45527 -6.36117e-08 1.875 0.419733 1.875 0.9375C1.875 1.45527 1.45527 1.875 0.9375 1.875C0.419733 1.875 -3.46183e-07 1.45527 -3.68815e-07 0.9375C-3.91447e-07 0.419733 0.419733 -1.83471e-08 0.9375 -4.09794e-08Z" />
    </svg>
  );
}
