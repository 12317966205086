import { CSSProperties } from 'react';

type ArrowRightIconProps = {
  className?: string;
  style?: CSSProperties;
};

export default function ArrowRightIcon({ className, style }: ArrowRightIconProps) {
  return (
    <svg className={className} style={style} width="12" height="8" viewBox="0 0 12 8" fill="none">
      <path
        d="M8.11138 0.144557C8.0391 0.0576438 7.93312 0.00531515 7.82005 0.000445201C7.70697 -0.00487598 7.597 0.0376929 7.51674 0.11796C7.43692 0.197778 7.39435 0.307751 7.39922 0.420827C7.40454 0.533902 7.45687 0.639884 7.54378 0.712162L10.4399 3.61177H0.867973C0.653355 3.62285 0.485291 3.80023 0.485291 4.01529C0.485291 4.22991 0.653355 4.40728 0.867973 4.41882H10.4399L7.53935 7.31445C7.3877 7.47319 7.3877 7.7233 7.53935 7.88204C7.61429 7.95743 7.71672 8 7.82315 8C7.92957 8 8.03155 7.95743 8.10694 7.88204L11.6944 4.29463L11.6939 4.29507C11.7711 4.22057 11.815 4.11814 11.815 4.01127C11.815 3.90396 11.7711 3.80153 11.6939 3.72748L8.11138 0.144557Z"
        fill="currentColor"
      />
    </svg>
  );
}
