import { CSSProperties } from 'react';

type HomeIconProps = {
  className?: string;
  style?: CSSProperties;
};

export default function HomeIcon({ className, style }: HomeIconProps) {
  return (
    <svg
      className={className}
      style={{
        width: '1em',
        ...style,
      }}
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00089 0.000713616C7.09381 0.00248918 7.18851 0.0356325 7.28025 0.0954085L13.0377 4.64076C13.1857 4.78754 13.2898 5.10358 13.1371 5.2989C12.9844 5.49421 12.6642 5.52024 12.479 5.35571L7.00093 1.03293L1.52287 5.35571C1.33762 5.52024 1.01034 5.49894 0.864733 5.2989C0.736895 5.12311 0.768262 4.78695 0.964162 4.64076L3.06169 2.98353V0.455097C3.06169 0.204164 3.26529 0.000562188 3.51623 0.000562188H4.72832C4.97986 0.000562188 5.18286 0.204164 5.18286 0.455097V1.31208L6.72161 0.095257C6.8163 0.0289706 6.90804 -0.00121353 7.00097 0.000562188L7.00089 0.000713616ZM4.27368 0.909784H3.97066V2.26866L4.27368 2.02718V0.909784ZM10.6372 4.43713L11.5462 5.15681V9.54595C11.5462 9.79689 11.3432 10.0005 11.0917 10.0005H8.51601C8.26507 10.0005 8.06147 9.79689 8.06147 9.54595V6.97025H5.94031V9.54595C5.94031 9.79689 5.73731 10.0005 5.48577 10.0005H2.91007C2.65914 10.0005 2.45554 9.79689 2.45554 9.54595V5.15681L3.36461 4.43713V9.09142H5.03124V6.51572C5.03124 6.26478 5.23484 6.06118 5.48577 6.06118H8.51601C8.76755 6.06118 8.97054 6.26478 8.97054 6.51572V9.09142H10.6372V4.43713Z"
        fill="currentColor"
      />
    </svg>
  );
}
