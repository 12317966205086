import { CSSProperties } from 'react';

type LockOpenIconProps = {
  className?: string;
  style?: CSSProperties;
  fill?: string;
};

export default function LockOpenIcon({ className, style, fill = '#434343' }: LockOpenIconProps) {
  return (
    <svg
      className={className}
      style={style}
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.24 4.84H2.365V1.76C2.365 1.33512 2.71013 0.99 3.135 0.99H6.545C6.96988 0.99 7.315 1.33512 7.315 1.76V2.695C7.315 2.7555 7.3645 2.805 7.425 2.805H8.195C8.2555 2.805 8.305 2.7555 8.305 2.695V1.76C8.305 0.787875 7.51713 0 6.545 0H3.135C2.16288 0 1.375 0.787875 1.375 1.76V4.84H0.44C0.196625 4.84 0 5.03662 0 5.28V10.56C0 10.8034 0.196625 11 0.44 11H9.24C9.48338 11 9.68 10.8034 9.68 10.56V5.28C9.68 5.03662 9.48338 4.84 9.24 4.84ZM8.69 10.01H0.99V5.83H8.69V10.01ZM4.455 8.09875V8.8275C4.455 8.888 4.5045 8.9375 4.565 8.9375H5.115C5.1755 8.9375 5.225 8.888 5.225 8.8275V8.09875C5.3385 8.01726 5.42322 7.90186 5.46696 7.76916C5.5107 7.63645 5.5112 7.49329 5.4684 7.36028C5.4256 7.22728 5.3417 7.11128 5.22877 7.02899C5.11585 6.9467 4.97973 6.90237 4.84 6.90237C4.70027 6.90237 4.56415 6.9467 4.45123 7.02899C4.3383 7.11128 4.2544 7.22728 4.2116 7.36028C4.1688 7.49329 4.1693 7.63645 4.21304 7.76916C4.25678 7.90186 4.3415 8.01726 4.455 8.09875V8.09875Z"
        fill={fill}
      />
    </svg>
  );
}
