import { CSSProperties } from 'react';

type SettingsIconProps = {
  className?: string;
  style?: CSSProperties;
  fill?: string;
};

export default function SettingsIcon({ className, style, fill }: SettingsIconProps) {
  return (
    <svg
      className={className}
      style={style}
      width="19"
      height="17"
      viewBox="0 0 19 17"
      fill={fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.56945 2.39228V0.500359H2.34455V2.39323C1.00673 2.67648 0 3.86599 0 5.28622C0 6.70731 1.00768 7.89674 2.3455 8.1792V16.5H3.57041L3.56945 8.17822C4.90727 7.89498 5.914 6.70643 5.914 5.28524C5.91496 3.86415 4.90727 2.67572 3.56945 2.39228ZM2.957 7.01826C2.00196 7.01826 1.22491 6.24121 1.22491 5.28521C1.22491 4.33018 2.00196 3.55217 2.957 3.55217C3.91204 3.55217 4.69005 4.32922 4.69005 5.28521C4.69005 6.24121 3.91299 7.01826 2.957 7.01826Z"
        fill={fill || 'currentColor'}
      />
      <path
        d="M9.70843 0.500269H8.48353V8.82106C7.14571 9.10336 6.13802 10.2929 6.13802 11.7141C6.13802 13.1352 7.14571 14.3236 8.48257 14.607V16.4999H9.70748V14.607C11.0453 14.3238 12.053 13.1343 12.053 11.7141C12.053 10.293 11.0463 9.10353 9.70843 8.82033V0.500269ZM9.09502 13.4461C8.13999 13.4461 7.36293 12.669 7.36293 11.713C7.36293 10.758 8.13999 9.98093 9.09502 9.98093C10.0501 9.98093 10.8281 10.758 10.8281 11.713C10.8281 12.669 10.051 13.4461 9.09502 13.4461Z"
        fill={fill || 'currentColor'}
      />
      <path
        d="M18.1919 6.48784C18.1919 5.06675 17.1842 3.87829 15.8464 3.59485V0.5H14.6215V3.59485C13.2837 3.8781 12.2769 5.06665 12.2769 6.48784C12.2769 7.90902 13.2846 9.09738 14.6215 9.38082V16.4988H15.8464V9.38082C17.1842 9.09758 18.1919 7.90807 18.1919 6.48784ZM15.234 8.21993C14.279 8.21993 13.5019 7.44287 13.5019 6.48688C13.5019 5.53185 14.279 4.75479 15.234 4.75479C16.189 4.75479 16.967 5.53185 16.967 6.48688C16.967 7.44287 16.19 8.21993 15.234 8.21993Z"
        fill={fill || 'currentColor'}
      />
    </svg>
  );
}
